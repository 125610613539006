.Categories {
  margin-top: 48px; }
.Categories-Container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px; }

.CategoryTile {
  display: block;
  width: 100%;
  font-family: var(--activeFont);
  // padding: 16px
  // border-top-left-radius: 24px
  // border-bottom-right-radius: 24px
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // img
  //   width: 100%
  //   height: 100px
  //   object-fit: contain
  min-height: 220px;
  background-size: cover;
  background-position: center center;
  padding: 24px;
  position: relative;
  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.2);
    position: absolute;
    top: 0;
    left: 0;
    // border-bottom-right-radius: 24px
 }    // border-top-left-radius: 24px
  p {
    font-size: 24px;
    text-transform: uppercase;
    font-weight: 800;
    color: #fff;
    line-height: 32px;
    position: relative;
    z-index: 1; }
  .PriceLine {
    display: flex;
    justify-content: space-between;
    z-index: 1;
    position: relative;
    align-items: center;
    .PriceFrom {
      padding: 4px 12px;
      font-size: 18px;
      border: 3px var(--accent) solid;
      border-bottom-right-radius: 16px;
      border-top-left-radius: 16px;
      color: #fff;
      .Price {
        font-weight: 800; } }
    .Icon-Container {
      width: 32px;
      height: 32px;
      background-color: #fff;
      font-size: 16px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 100%;
      color: var(--accent);
      box-shadow: var(--boxShadow); } }
  &:hover {
    opacity: 0.7;
    box-shadow: var(--boxShadow); } }

@media screen and ( max-width: 576px ) {
  .Categories-Container {
    grid-template-columns: 1fr; } }
