.Nav {
  width: 100%;
  height: 64px;
  background-color: var(--accent2);
  position: fixed;
  top: 64px;
  z-index: 100; }

.Nav-Container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;
  .Cart-Button {
    margin-left: 32px; } }

.Nav-Menu {
  display: flex;
  flex-shrink: 0;
  .GroupLink-Parent {
    position: relative;
    cursor: pointer; }
  .GroupLink {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    grid-gap: 12px;
    align-items: center;
    padding: 0px 12px;
    .Icon {
      color: #fff;
      font-size: 16px; }
    .Menu-Link {
      padding: 0; } }
  .GroupLink-Menu {
    display: none;
    grid-gap: 12px;
    grid-template-columns: 1fr;
    padding: 12px;
    background-color: #fff;
    box-shadow: var(--boxShadow);
    position: absolute;
    top: 64px;
    min-width: 200px;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    .ChildLink {
      color: var(--accent2);
      height: auto;
      padding-bottom: 12px;
      border-bottom: 1px var(--lightGray) solid;
      &:hover {
        color: var(--accent); }
      &:last-child {
        border-bottom: 0; } } }
  .Menu-Link {
    font-family: var(--activeFont);
    font-size: 14px;
    text-transform: uppercase;
    color: #fff;
    height: 64px;
    display: flex;
    align-items: center;
    padding: 0px 12px;
    img {
      height: 18px;
      margin-right: 12px; } }
  .Spoiler_status_expanded {
    .GroupLink {
      background-color: #fff;
      .Menu-Link {
        color: var(--accent2) !important; }
      .Icon {
        transform: rotate(180deg);
        color: var(--accent2); }
      .MenuIcon {
        filter: invert(20%) sepia(51%) saturate(5588%) hue-rotate(332deg) brightness(95%) contrast(96%); } }
    .GroupLink-Menu {
      display: grid; } } }

@media ( max-width: 1024px) {
  .Nav {
    top: 0;
    background-color: #fff;
    .Site-Hamburger-Container {} }
  .Nav-Container {
    display: grid;
    grid-template-columns: max-content 1fr max-content max-content;
    grid-gap: 24px;
    align-items: center; }
  .Nav-Menu {
    position: absolute;
    background-color: #fff;
    top: 64px;
    display: block;
    padding-bottom: 16px;
    box-shadow: var(--boxShadow);
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    display: none;
    li {
      padding: 8px 16px;
      &:hover {
        background-color: var(--accent); } }
    .GroupLink {
      img {
        filter: invert(20%) sepia(51%) saturate(5588%) hue-rotate(332deg) brightness(95%) contrast(96%) !important; }
      .Icon {
        color: var(--accent2); } }
    .GroupLink-Menu {
      position: relative;
      top: 0;
      box-shadow: none;
      background: none;
      padding: 12px 12px 12px 32px; }
    .Menu-Link {
      color: var(--accent2);
      img {
        filter: invert(20%) sepia(51%) saturate(5588%) hue-rotate(332deg) brightness(95%) contrast(96%) !important; } } } }
