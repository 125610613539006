.SectionTitle {
  font-size: 22px;
  font-family: var(--activeFont);
  font-weight: 400;
  text-transform: uppercase;
  color: var(--accent2);
  &:after {
    content: '';
    position: absolute;
    margin-top: 12px;
    display: block;
    width: 80px;
    height: 3px;
    background-color: var(--accent);
    border-radius: 3px; } }
