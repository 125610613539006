.Footer {
  width: 100%;
  background-color: #353B5F;
  padding: 48px 0px; }

.Footer-Container {
  display: flex;
  justify-content: space-between; }

.Footer-BlockTitle {
  font-size: 18px;
  color: #fff;
  text-transform: uppercase;
  line-height: 18px;
  margin-bottom: 8px; }

.Footer-MainText {
  font-size: 12px;
  font-weight: 700;
  color: #fff;
  line-height: 24px; }
.Footer-SimpleText {
  color: var(--descText);
  font-weight: 600;
  font-size: 12px;
  line-height: 24px; }
.Footer-Menu {
  display: grid;
  grid-template-columns: 1fr; }
.Footer-Link {
  font-size: 12px;
  color: var(--descText);
  font-weight: 500;
  &:hover {
    color: var(--accent); } }
.Footer-AccentLink {
  font-size: 12px;
  font-weight: 500;
  color: #fff;
  &:hover {
    color: var(--accent); } }

.Infoline {
  display: flex;
  .Infoline-Icon {
    width: 36px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
    color: var(--accent);
    background: #fff;
    border-top-left-radius: 16px;
    border-bottom-right-radius: 16px;
    margin-right: 12px; }
  .Infoline-Phone {
    font-size: 18px;
    color: #fff;
    text-transform: uppercase;
    line-height: 18px;
    margin-bottom: 8px;
    font-weight: 700;
    margin-top: 16px;
    margin-bottom: 16px;
    &:hover {
      color: var(--accent); } }
  .Infoline-Soc {
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    font-size: 18px;
    color: #fff;
    cursor: pointer;
    width: 100%;
    .Soc-Icons {
      a {
        color: #fff; }
      a:not(:last-child) {
        margin-right: 8px; }
      a:hover {
        color: var(--accent); } }
    img {
      height: 32px; } } }

@media ( max-width: 1024px) {
  .Footer-Container {
    display: grid;
    grid-template-columns: 1fr 220px;
    grid-gap: 32px;
    .PartnersBlock {
      padding-left: 48px; } } }

@media ( max-width: 576px) {
  .Footer-Container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 24px;
    .Footer-LinkBlock {
      text-align: center; }
    .Infoline {
      justify-content: center;
      order: 3; }
    .ClientsBlock {
      order: 0; }
    .PartnersBlock {
      padding-left: 0px;
      order: 1; }
    .ContactsBlock {
      order: 2; } } }
