.Faq {}
.Faq-Container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 32px;
  .Question {
    background-color: var(--lightGray);
    padding: 24px;
    .qIcon-Plus {
      display: block; }
    .qIcon-Minus {
      display: none; }
    .Question-Header {
      display: flex;
      align-items: center;
      cursor: pointer; }
    .Question-Icon {
      width: 32px;
      height: 32px;
      background-color: var(--black);
      display: flex;
      justify-content: center;
      align-items: center;
      color: #fff;
      margin-right: 12px;
      flex-shrink: 0; }
    .Question-Title {
      font-size: 18px;
      color: var(--black);
      font-weight: 700;
      font-family: var(--activeFont); }
    .Question-Body {
      margin-top: 16px;
      color: var(--black);
      display: none; }
    ul {
      list-style-type: disc;
      padding-left: 16px; }
    p {
      &:not(:last-child) {
        margin-bottom: 16px; } } }
  .Spoiler_status_expanded {
    .Question-Body {
      display: block !important; }
    .qIcon-Plus {
      display: none; }
    .qIcon-Minus {
      display: block; } } }
.Faq-Buttons {
  margin-top: 32px;
  display: flex;
  justify-content: center; }
