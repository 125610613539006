.Carousel {
  position: relative; }
.Carousel-DotsContainer {
  position: absolute;
  bottom: -36px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  height: 12px;
  .Carousel-Dot {
    width: 12px;
    height: 12px;
    background-color: var(--lightGray);
    cursor: pointer;
    appearance: none;
    outline: none;
    display: block;
    &:not(:last-child) {
      margin-right: 8px; }
    &:hover {
      background-color: var(--accent); } }
  .glide__bullet--active {
    background-color: var(--accent) !important; } }
.Carousel-Arrow {
  width: 32px;
  height: 32px;
  position: absolute;
  top: 50%;
  border: 0px;
  transform: translateY(-50%);
  display: flex;
  background: none;
  justify-content: center;
  align-items: center;
  appearance: none;
  outline: none;
  .Icon {
    color: var(--accent) !important;
    font-size: 32px; }
  svg {
    color: #fff; } }
.Arrow-Left {
  left: 0px; }
.Arrow-Right {
  right: 0px; }
