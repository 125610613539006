.ProductSlider {
  padding: 64px 0px; }

.ProductsSlider-Carousel {
  .glide__slides {
    padding: 16px 0px; } }

.ProductsSlider-Container {
  margin-top: 48px; }

.ProductTile {
  width: 100%;
  background-color: #fff;
  box-shadow: var(--boxShadow);
  // border-top-left-radius: 24px
  // border-bottom-right-radius: 24px
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  justify-content: space-between;
  height: auto !important;

  .Product-Cat {
    display: flex;
    align-items: center;
    font-size: 14px;
    text-transform: uppercase;
    color: var(--accent2);
    font-weight: 500;
    margin-bottom: 16px;
    .Icon {
      margin-left: 8px; }
    &:hover {
      color: var(--accent) !important; } }

  .Product-Img {
    width: 100%;
    height: 213px;
    object-fit: cover;
    object-position: center center;
    margin-bottom: 24px {
 } }      // border-top-left-radius: 24px
  // border-bottom-right-radius: 24px
  .Product-Title {
    font-size: 14px;
    text-transform: uppercase;
    text-align: center;
    color: var(--accent2);
    font-weight: 600;
    margin-bottom: 16px; }
  .Product-Desc {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
    color: var(--descText);
    margin-bottom: 24px;
    white-space: normal; }
  .Product-Buttons {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .Product-Price {
    padding: 0px 12px;
    font-size: 13px;
    line-height: 16px;
    font-weight: 500;
    color: var(--accent);
    border: 2px solid var(--accent);
    border-top-left-radius: 16px;
    border-bottom-right-radius: 16px;
    font-family: var(--activeFont);
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 44px; }
  .QuantityManager {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    grid-gap: 16px;
    align-items: center;
    .RoundedIcon {
      width: 32px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top-left-radius: 16px;
      border-bottom-right-radius: 16px;
      font-weight: 600;
      font-size: 18px;
      color: #fff;
      background-color: var(--accent);
      cursor: pointer; }
    .Quantity {
      font-weight: 600;
      border-bottom: 1px var(--descText) solid;
      padding: 0px 4px; } } }

@media screen and (max-width: 1200px) {
  .ProductTile {
    .Product-Buttons {
      flex-direction: column;
      .Product-Price {
        margin-bottom: 16px; }
      .ActiveButton {
        width: 100%;
        justify-content: center; } } } }

@media screen and (max-width: 576px) {
  .ProductTile {
    max-width: 360px;
    .Product-Buttons {
      display: grid;
      grid-gap: 16px;
      grid-template-columns: repeat(2, max-content);
      justify-content: space-between;
      .Product-Price {
        margin-bottom: 0;
        text-align: center;
        padding: 0px 16px; }

      .ActiveButton {
        width: 100%;
        justify-content: center; } } }
  .ProductsSlider-Container {
    display: flex;
    justify-content: center; }
  .ProductsSlider-Carousel {
    max-width: 360px; } }
