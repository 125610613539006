.SiteMap-Container {
  ul {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    margin-left: 24px; }
  a {
    color: var(--accent);
    &:hover {
      color: var(--accent2); } } }
