.Partners {
  width: 100%;
  background-color: var(--accent); }
.Partners-Container {
  display: grid;
  grid-template-columns: repeat(5, minmax(0, 1fr));
  grid-gap: 32px;
  .PartnerTile {
    width: 100%;
    height: 124px;
    padding: 8px;
    background-color: #fff;
    span {
      width: 100%;
      height: 100%; }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
      object-position: center center; } } }

@media screen and ( max-width: 576px ) {
  .Partners-Container {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 24px;
    .PartnerTile {
      display: block;
      padding: 8px; } } }
