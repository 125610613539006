.About {
  width: 100%;
  background-color: #fff; }

.AboutText {
  padding: 64px 64px 64px 0px;
  p {
    margin-top: 48px;
    margin-bottom: 24px; }
  .ActiveButton {
    margin-top: 24px; } }
.AboutImg {
  width: 100%;
  height: 100%;
  background-size: cover; }

.TwoSideContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr); }

@media ( max-width: 768px) {
  .TwoSideContainer {
    grid-template-columns: 1fr;
    grid-gap: 32px; }
  .AboutText {
    padding: 0;
    padding-top: 64px; }
  .AboutImg {
    height: 400px !important;
    background-size: 800px auto;
    background-position: center right;
    margin-bottom: 64px; } }
