.PageText {
  margin-top: 48px;
  p {
    line-height: 24px;
    margin-bottom: 16px;
    a {
      color: var(--accent);
      text-decoration: underline !important; }
    .Bolder {
      font-weight: 600; }
    .Accent {
      color: var(--accent); } } }
