.TourPage {
  .BreadcrumbList {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    list-style-type: none;
    margin: 0;
    padding: 0;
    grid-gap: 16px;
    text-transform: uppercase;
    font-family: var(--activeFont);
    margin-bottom: 24px;
    a {
      color: var(--darkGray);
      &:hover {
        text-decoration: underline;
        color: var(--accent); } } }
  .Title {
    font-size: 48px;
    font-family: var(--activeFont); } }
.TourPage-Content {
  display: grid;
  grid-template-columns: 550px 1fr;
  grid-gap: 64px;
  .Tour-Slider {
    width: 100%;
    height: 400px;
    img {
      object-fit: cover;
      width: 100%;
      height: 400px;
      object-position: top center; } }
  .Tour-Data {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 24px;
    align-content: start;
    ul, ol {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 16px;
      list-style: none;
      margin-left: 0 !important;
      padding-left: 0 !important;
      margin-top: 16px; }
    li {
      padding-left: 24px;
      position: relative;
      &::before {
        content: '';
        display: block;
        width: 14px;
        height: 14px;
        background-color: var(--accent);
        border-radius: 100%;
        position: absolute;
        top: 4px;
        left: 0; }
      .Bolder {
        font-weight: 700; } } } }
.TourPage-Program {
  margin-top: 64px;
  display: grid;
  grid-template-columns: 1fr 550px;
  grid-gap: 64px;
  .Tour-Img {
    width: 100%;
    height: 400px;
    span {
      width: 100%;
      height: 100%; }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center center; } }
  .Program, .Included {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 24px; }
  h1 {
    font-family: var(--activeFont);
    text-transform: uppercase;
    padding-left: 0 !important;
    font-size: 18px;
    font-weight: 700;
    color: var(--sea);
    &::before {
      display: none !important; } }
  .Text {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 24px;
    ul {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 24px; }
    li {
      padding-left: 26px;
      position: relative;
      &::before {
        content: '';
        display: block;
        width: 14px;
        height: 14px;
        background-color: var(--accent);
        border-radius: 100%;
        position: absolute;
        top: 4px;
        left: 0; }
      .Bolder {
        font-weight: 700; } } } }
.TourPage-OtherDetails {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 64px;
  margin-top: 64px;
  .Included, .HowToPrepare {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 24px;
    align-self: start;
    .Text {
      line-height: 24px; } } }

@media screen and ( max-width: 1200px ) {
  .TourPage-Content {
    grid-template-columns: 450px 1fr; }
  .TourPage-Program {
    grid-template-columns: 1fr 450px; } }
@media screen and ( max-width: 1024px ) {
  .TourPage-Content, .TourPage-Program {
    grid-template-columns: 1fr;
    .Tour-Slider {
      width: 720px;
      margin-left: -16px;
      order: 1; }
    .Tour-Data {
      order: 0; } }
  .TourPage-OtherDetails {
    grid-template-columns: 1fr; } }

@media screen and ( max-width: 768px ) {
  .TourPage-Content, .TourPage-Program {
    .Tour-Slider {
      width: 540px; } } }

@media screen and ( max-width: 576px ) {
  .TourPage {
    .Title {
      font-size: 36px;
      line-height: 46px; } }
  .TourPage-Content, .TourPage-Program {
    .Tour-Slider {
      width: 375px;
      margin-left: 50%;
      transform: translateX(-50%);
      height: 300px;
      img {
        height: 300px; } } } }
