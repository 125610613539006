.CheckTag {
  width: 24px;
  height: 24px;
  background-color: #fff;
  border-top-left-radius: 8px;
  border-bottom-right-radius: 8px;
  flex-shrink: 0;
  margin-right: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff; }

.CheckTag_theme_active {
  background-color: var(--accent); }
