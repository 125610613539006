.Slider {
  width: 100%;
  margin-top: 24px;
  // border-top-left-radius: 64px
  // border-bottom-right-radius: 64px
  .glide__slides {
    height: 350px;
    box-shadow: var(--boxShadow);
    // border-top-left-radius: 64px
 }    // border-bottom-right-radius: 64px
  a {
    width: 100%;
    height: 350px; }
  img {
    width: 100%;
    // border-top-left-radius: 64px
    // border-bottom-right-radius: 64px
    height: 100% !important;
    object-position: top center;
    object-fit: cover; }
  .Slider-Arrows {
    .Carousel-Arrow {
      width: 16px;
      height: 32px;
      font-size: 32px;
      color: var(--accent);
 }      // margin-top: -25px
    .Arrow-Left {
      left: 24px; }
    .Arrow-Right {
      right: 24px; } }

  .Carousel-DotsContainer {
    margin: 0px 0px; }
  .Carousel-Dot {
    display: block;
    width: 24px;
    height: 3px;
    border-radius: 2px;
    background-color: var(--accent2); } }

@media ( max-width: 1024px) {
  .Slider {
    .glide__slides {
      height: 300px; }
    a {
      height: 300px; }
    img {
      object-fit: cover;
      object-position: top 0px right -60px; } } }

@media ( max-width: 768px) {
  .Slider {
    .glide__slides {
      height: 220px; }
    a {
      height: 220px; }
    img {
      object-fit: cover;
      object-position: top center; } } }
