.MobileTable {
  th {
    &:last-child {
      text-align: left; } }
  .MobileCell {
    padding: 16px 0px; }
  .BlockTitle {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    margin-right: 12px;
    padding-left: 80px;
    font-family: var(--activeFont); }
  .CartTable-QuantityBlock, .CartTable-ProdPrice, .CartTable-ProdPrice {
    margin-top: 12px; } }
