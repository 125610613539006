.TourCatalog {}
.TourCatalog-Container {
  .Catalog-Tiles {
    display: grid;
    grid-template-columns: repeat(5, minmax(0, 1fr));
    grid-gap: 32px;
    align-items: start; } }

.TourTile, .TourTileSkeleton {
  box-shadow: var(--boxShadow);
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  height: 100%;
  cursor: pointer;
  .Avatar {
    width: 100%;
    height: 260px;
    margin-bottom: 24px;
    span {
      width: 100%;
      height: 100%;
      border-radius: 0px !important; }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: top center; } }
  .Tile-Content {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 12px;
    margin-bottom: 24px;
    padding: 0px 12px;
    .Title {
      font-size: 16px;
      line-height: 26px;
      font-weight: 700;
      color: var(--accent);
      text-align: center; }
    .InfoLine {
      font-size: 12px;
      line-height: 22px;
      font-weight: 700; }
    .Bolder {
      font-weight: 400; } }
  .Button {
    margin-bottom: 12px;
    margin-top: auto; }
  .SkeletonButton {
    margin-bottom: 12px; }
  .SkeletonContent {
    justify-self: start;
    width: 140px;
    display: grid;
    grid-template-columns: 1fr;
    text-align: left; } }

@media screen and ( max-width: 1200px ) {
  .TourCatalog-Container {
    .Catalog-Tiles {
      grid-template-columns: repeat(4, minmax(0, 1fr)); } } }

@media screen and ( max-width: 1024px ) {
  .TourCatalog-Container {
    .Catalog-Tiles {
      grid-template-columns: 1fr;
      align-items: start; } }
  .TourTile {
    display: grid;
    grid-template-columns: 200px 1fr max-content;
    height: 100%;
    cursor: pointer;
    justify-items: start;
    align-items: start;

    .Avatar {
      height: 100%;
      max-height: 150px;
      margin-bottom: 0; }
    .Button {
      align-self: center;
      margin-top: 0;
      margin-right: 12px; }
    .Tile-Content {
      .Title {
        text-align: left; } } } }

@media screen and ( max-width:  768px) {
  .TourTile {
    grid-template-columns: 200px 1fr;
    .Button {
      display: none; } } }

@media screen and ( max-width: 576px ) {
  .TourTile {
    grid-template-columns: 150px 1fr; } }
