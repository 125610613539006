.CheckOut-Container {
  display: flex; }
.CheckOut-Block {
  width: 100%;
  .CategoryTitle {
    border-bottom: 1px solid var(--borderColor); } }
.Steps {
  display: flex;
  margin-top: 16px;
  margin-bottom: 32px; }
.Step1 {
  width: 100%; }
.Step2 {
  width: 336px;
  flex-shrink: 0; }
.Step-Title {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 700;
  padding-bottom: 16px;
  border-bottom: 1px solid var(--borderColor); }
.StepIcon {
  width: 42px;
  height: 42px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--accent);
  font-size: 24;
  font-weight: 700;
  color: #fff;
  border-top-left-radius: 16px;
  border-bottom-right-radius: 16px;
  margin-right: 16px; }
.Step2 {
  .StepIcon {
    margin-left: 16px; } }
.CheckOut-CartContainer {
  display: flex; }
.CheckOut-CartIcon {
  font-size: 32px;
  color: #fff; }
.CheckOut-CartDetails {
  width: 100%;
  display: flex;
  margin-left: 16px;
  justify-content: space-between; }
.CheckOut-ReturnLink {
  font-size: 12px !important;
  color: var(--descText) !important;
  text-transform: none !important;
  font-weight: 500;
  i {
    margin-right: 4px; } }
.CheckOut-TotalSum {
  font-size: 14px;
  color: #ffffff;
  font-weight: 500; }

.CheckOut-Form {
  width: 100%;
  display: flex;
  margin-top: 16px;
  .Step2 {
    display: none; }
  .LeftBlock {
    width: 100%;
    padding-right: 16px;
    display: grid;
    grid-template-columns: 1fr;
    align-items: start;
    align-self: start;
    grid-gap: 32px; }
  .RightBlock {
    width: 336px;
    padding-left: 16px;
    border-left: 1px var(--borderColor) solid;
    flex-shrink: 0;
    align-items: start;
    .TwoCols {
      display: grid;
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-gap: 32px; }
    .AddressBlock, .PaymentTypes {
      grid-column: 1/-1; }
    .AddressBlock {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 32px; } }
  .PaymentTypes {
    .Title {
      font-size: 12px;
      font-weight: 500; }

    .Container {
      margin-top: 8px;
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 24px;
      justify--self: start; }
    .PaymentMethod {
      padding: 12px 16px;
      width: 100%;
      background-color: #fff;
      display: grid;
      grid-template-columns: max-content max-content 1fr;
      align-items: center;
      grid-gap: 12px;
      font-size: 16px;
      border: 2px #fff solid;
      cursor: pointer;
      span {
        font-size: 14px; }
      &:hover {
        border: 2px var(--accent) solid; } }
    .PaymentMethod_theme_active {
      border: 2px var(--accent) solid;
      background-color: var(--accent);
      color: #fff;
      .CheckTag {
        background-color: #fff;
        color: var(--accent); } } }
  .ConfirmButton {
    width: 100%;
    display: flex;
    justify-content: center;
    grid-column: 1/-1;
    a {
      max-width: 200px;
      justify-content: center; } } }

@media (max-width: 1200px) {
  .Step1, .Step2 {
    width: 50%; }
  .CheckOut-Form {
    .LeftBlock, .RightBlock {
      width: 50%; } } }

@media ( max-width: 1024px) {
  .CheckOut-Container {
    flex-direction: column;
    .CheckOut-Block {
      order: 1; }
    .Cart-Totals {
      order: 0;
      margin-top: 0;
      margin-bottom: 32px; } } }

@media ( max-width: 768px) {
  .CheckOut-Form {
    .LeftBlock {
      padding-right: 0;
      grid-template-columns: 1fr;
      grid-gap: 12px; }
    .RightBlock {
      border-left: 0; } } }

@media ( max-width: 576px) {
  .Steps {
    .Step1 {
      width: 100%; }
    .Step2 {
      display: none; } }
  .CheckOut-Form {
    flex-direction: column;
    .LeftBlock {
      width: 100%; }
    .Step2 {
      display: flex;
      width: 100%;
      margin-top: 12px;
      margin-bottom: 16px;
      .StepIcon {
        margin-left: 0; } }
    .RightBlock {
      width: 100%;
      padding-left: 0; } } }
