.Products {
  .BreadCrumbs {
    display: grid;
    grid-template-columns: repeat(3, max-content);
    grid-gap: 12px;
    align-items: center;
    color: var(--descText);
    margin-bottom: 24px;
    a {
      color: var(--descText);
      &:hover {
        text-decoration: underline !important;
        color: var(--accent); } }
    .ActiveCat {
      color: var(--accent); } } }
.Products-Container {
  display: flex;
  .Spoiler {
    display: none; } }
.Products-Banner {
  width: 100%;
  height: 250px;
  object-fit: cover;
  object-position: center center;
  margin-top: 32px; }

.Categories-Nav {
  width: 264px;
  padding-right: 16px;
  border-right: 1px var(--borderColor) solid;
  flex-shrink: 0;
  .Category {
    font-size: 16px;
    color: var(--accent2);
    text-transform: uppercase;
    height: 32px;
    display: flex;
    align-items: center;
    font-weight: 700;
    cursor: pointer;
    &:not(:first-child) {
      margin-top: 16px; }
    a {
      color: var(--accent2); }
    &:hover {
      color: var(--accent);
      .CheckTag {
        background-color: var(--accent); } } }
  .Category_theme_active {
    .CheckTag {
      background-color: var(--accent); } }
  .Subcategory {
    margin-top: 12px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 500;
    color: var(--accent2);
    cursor: pointer;
    &:hover {
      .CheckTag {
        background-color: var(--accent); } }
    a {
      color: var(--accent2);
      display: flex; } }
  .Subcategory_theme_active {
    .CheckTag {
      background-color: var(--accent); } } }

.Products-Content {
  width: 100%;
  padding-left: 16px; }
.FilterLine {
  &:first-child {
    margin-bottom: 16px; }
  &:last-child {
    margin-top: 16px; } }
.Products-Tiles {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 24px;
  margin-top: 32px; }

@media screen and ( max-width: 576px ) {
  .Products-Container {
    .Categories-Nav {
      display: none; } }
  .Products-Content {
    padding-left: 0; }
  .Products-Tiles {
    grid-template-columns: 1fr;
    .ProductTile {
      max-width: 100%; } } }
// @media ( max-width: 1024px)
//   .Products-Tiles
//     grid-template-columns: repeat(2, 1fr)
//     grid-gap: 16px

// @media ( max-width: 768px)
//   .Propducst-Container
//     flex-direction: column
//     .Spoiler
//       display: flex
//   .DesktopCategories
//     display: none
//   .Categories-Nav
//     width: 100%
//     padding-right: 0
//     border-right: 0
//     ul
//       display: flex
//       flex-wrap: wrap
//       li
//         margin-right: 12px
//         margin-bottom: 12px
//   .Products-Content
//     margin-top: 32px
//     padding-left: 0

// @media ( max-width: 576px)
//   .Products-Content
//     display: flex
//     justify-content: center
//     flex-direction: column
//     align-items: center
//   .Products-Tiles
//     max-width: 320px
//     grid-template-columns: 1fr
