.Benefits {
  padding: 64px 0px; }
.Benefits-Container {
  margin-top: 48px;
  display: inline-grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 32px; }
.Benefit {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: center
  .Benefit-Icon {
    width: 80px;
    height: 80px;
    background-color: #fff;
    border-top-left-radius: 24px;
    border-bottom-right-radius: 24px;
    box-shadow: var(--objectShadow);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 24px; }
  .Benefit-Title {
    text-transform: uppercase;
    font-size: 16px;
    color: var(--accentColor2);
    font-weight: 500;
    text-align: center; }
  .Benefit-Desc {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: var(--descText);
    margin-top: 16px; } }

@media ( max-width: 768px) {
  .Benefits-Container {
    grid-template-columns: repeat(2, 1fr); } }
