.NotFound {
  background-size: cover;
  height: calc(100vh - 64px);
  color: #fff;
  display: flex;
  align-items: center; }

.NotFound-Container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .Title {
    font-family: var(--activeFont);
    font-size: 32px;
    text-transform: uppercase;
    margin-bottom: 24px; }
  .Description {
    margin-bottom: 24px;
    font-size: 16px; } }
