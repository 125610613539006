.LegacyInfo-Container {
  padding: 24px 0px;
  border-top: 1px var(--lightGray) solid;
  font-size: 13px;
  p {
    &:not(:last-child) {
      margin-bottom: 8px; } }
  a {
    color: var(--accent);
    text-decoration: underline !important; } }
