.Feeds {
  width: 100%;
  background: linear-gradient(to right, #fff 50%, #F7F7F7 50%); }
.Feeds-Container {
  width: 100%;
  display: flex;
  justify-content: center; }
.FeedTile {
  display: grid;
  grid-template-columns: 238px 1fr;
  grid-gap: 64px;
  width: 100%;
  .User {
    width: 100%;
    .Avatar {
      width: 238px;
      height: 238px;
      img {
        border-radius: 100%;
        width: 100%;
        height: 100%;
        object-position: top center;
        object-fit: cover; } }
    .UserName {
      font-size: 18px;
      font-family: var(--activeFont);
      text-align: center;
      margin-top: 24px;
      text-transform: uppercase;
      color: var(--sea); } }
  .Feed {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    .Title {
      text-transform: uppercase;
      font-size: 18px;
      font-weight: 600;
      color: var(--sea); }
    .Feed-Short {
      display: grid;
      grid-template-columns: 36px 1fr;
      grid-gap: 16px;
      margin-bottom: 16px; }
    .Photos {
      padding-left: 52px;
      display: grid;
      grid-template-columns: repeat(5, minmax(0, 1fr));
      height: 100px;
      grid-gap: 16px;
      .FeedPhoto {
        width: 100%;
        height: 100px;
        object-fit: cover;
        object-position: center center;
        cursor: pointer;
        &:hover {
          opacity: 0.8; } } }
    .Buttons {
      padding-left: 52px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .ReadMore {
        color: var(--sea);
        text-decoration: underline; } }

    .Arrows {
      display: flex;
      .Arrow {
        width: 32px;
        height: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        color: #fff;
        background-color: var(--black);
        cursor: pointer;
        &:first-child {
          margin-right: 16px; } } } } }
.FeedPopUp {
  .PopUp-Content {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 16px;
    margin-top: 16px; }
  .Buttons {
    display: flex;
    justify-content: center; } }

@media screen and ( max-width: 576px ) {
  .FeedTile {
    grid-template-columns: 1fr;
    grid-gap: 48px;
    .User {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center; }
    .Feed {
      .Photos {
        grid-template-columns: repeat(2, 1fr);
        height: auto; } } } }
@media screen and ( max-width: 1024px ) {
  .FeedTile {
    .Feed {
      .Photos {
        grid-template-columns: repeat(2, 1fr);
        height: auto; } } } }
