.Top {
  width: 100%;
  height: 64px;
  background: linear-gradient(90deg, #ffffff 50%, var(--accent) 50%);
  position: fixed;
  top: 0px;
  z-index: 100;
  box-shadow: var(--boxShadow);
  .Soc-Icons {
    font-size: 22px;
    display: grid;
    grid-template-columns: repeat(2, max-content);
    grid-gap: 12px;
    margin: 0px 12px;
    a {
      color: var(--accent); } } }

.Top-Container, .Top-Right {
  display: flex;
  align-items: center;
  background-color: #fff;
  height: 64px; }
.Top-Container {
  justify-content: space-between; }
.Top-Nav {
  display: flex;
  .Site-NavLink {
    font-family: var(--activeFont);
    margin-right: 24px;
    text-transform: uppercase;
    font-weight: 500;
    color: var(--accent2);
    &:hover {
      color: var(--accent); } } }

.TopContact {
  display: flex;
  align-items: center;
  background-color: var(--accent);
  height: 64px;
  padding-left: 16px;
  .ContactIcon {
    margin-right: 12px;
    color: var(--accent); } }

.ContactIcon {
  width: 36px;
  flex-shrink: 0;
  height: 36px;
  background-color: #fff;
  box-shadow: var(--boxShadow);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  color: var(--accent);
  border-top-left-radius: 16px;
  border-bottom-right-radius: 16px; }

.ContactPhone {
  text-align: right;
  .Phone-Number {
    font-size: 18px;
    color: #fff;
    font-weight: 600; }
  .Phone-Desc {
    font-size: 12px;
    font-weight: 600;
    color: #fff;
    margin-top: -4px; } }

@media ( max-width: 1024px) {
  .Top {
    background: #fff;
    display: none; }
  .TopContact {
    padding: 0px 16px;
    .ContactIcon {
      margin-right: 0; } }
  .ContactPhone {
    display: none; } }
@media ( max-width: 768px) {
  .Top-Nav {
    display: none; } }
