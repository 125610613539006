.Cart-Button * {
  color: #fff;
  font-family: var(--activeFont); }
.Cart-Button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  p {
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    margin-top: 4px; }

  .Cart-Counter {
    width: 16px;
    height: 16px;
    position: absolute;
    font-size: 8px;
    color: #fff;
    background-color: var(--accent);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    top: 0;
    right: 12px; }
  .Cart-Icon {
    font-size: 24px; } }

@media screen and ( max-width: 576px) {
  .Cart-Button * {
    color: var(--accent2); } }
