.TourConstructor {
  background-size: cover;
  background-position: top center;
  position: relative; }

.TourConstructor-AccentBackdrop {
  width: 50%;
  height: 100%;
  background-color: rgba(113, 130, 106, 0.8);
  display: block;
  position: absolute;
  top: 0;
  left: 0; }

.TourConstructor-Container {
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  .Site-StatusMessage {
    margin-left: 48px; }
  .Info {
    max-width: 400px;
    .Title {
      font-size: 48px;
      font-weight: 500;
      color: #fff;
      line-height: 52px; }
    .Description {
      font-size: 18px;
      color: #fff;
      font-family: var(--activeFont);
      margin-top: 16px;
      font-weight: 400; } }
  .Form {
    width: 100%;
    max-width: 500px;
    justify-self: end;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 24px;
    align-self: start;
    .FormButton {
      margin-left: 48px; } }
  .FormStep {
    display: grid;
    grid-template-columns: 32px 1fr;
    grid-gap: 16px;
    .Number {
      width: 32px;
      height: 32px;
      background-color: var(--sea);
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      color: #fff; }
    .Title {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 16px; } } }
.TourSelector {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 16px;
  border-radius: 8px;
  .Tile {
    padding: 8px;
    background-color: #fff;
    border: 1px var(--lightGray) solid;
    display: grid;
    grid-template-columns: 16px 1fr;
    grid-gap: 8px;
    cursor: pointer;
    .Icon {
      margin-top: 4px;
      color: #fff; }
    // &:hover
    //   background-color: var(--sea)
    //   color: #fff
 }    //   border: 1px var(--sea) solid
  .Tile_status_active {
    background-color: var(--sea);
    color: #fff;
    border: 1px var(--sea) solid; } }

@media screen and ( max-width: 1200px ) {
  .TourConstructor-Container {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 48px;
    .Info {
      max-width: 100%;
      .Title, .Description {
        color: var(--black); } }
    .Form {
      width: 100%;
      max-width: 100%; } }
  .TourConstructor-AccentBackdrop {
    display: none; } }
@media screen and ( max-width: 768px ) {
  .TourConstructor-Container {
    .Info {
      .Title {
        font-size: 24px;
        line-height: 36px; }
      .Description {
        font-size: 16px; } } } }
@media screen and ( max-width: 576px ) {
  .TourSelector {
    grid-template-columns: 1fr; } }
