.CartTable {
  width: 100%;
  border-collapse: collapse;
  thead {
    border-top: 1px var(--borderColor) solid;
    border-bottom: 1px var(--borderColor) solid; }
  th {
    padding: 16px 16px 16px 0px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 400;
    color: var(--accentColor2);
    font-family: var(--activeFont);
    &:last-child {
      padding-right: 0;
      text-align: right; } }
  tr {
    border-bottom: 1px var(--borderColor) solid;
    border-collapse: collapse; }
  td {
    &:not(:last-child) {
      padding: 16px 16px 16px 0px; } }

  .ModuleSpinner-Container {
    margin: 16px 0px; } }

.CartTable-DeleteIcon {
  width: 32px;
  text-align: center;
  font-size: 18px;
  color: var(--descText);
  cursor: pointer; }
.CartTable-ProductInfo, .CartTable-QuantityBlock {
  display: flex;
  .Product-Avatar {
    width: 64px;
    height: 64px;
    background-color: #fff;
    box-shadow: var(--boxShadow);
    border-top-left-radius: 16px;
    border-bottom-right-radius: 16px;
    margin-right: 16px;
    padding: 8px;
    img {
      width: 48px;
      height: 48px;
      object-fit: contain;
      object-position: center center; } }
  .Product-Details {
    h4 {
      text-transform: uppercase;
      color: var(--accent2);
      font-weight: 500;
      font-size: 14px;
      padding: 0;
      margin-bottom: 8px;
      font-family: var(--activeFont); }
    p {
      color: var(--descText);
      font-size: 12px;
      font-weight: 500;
      text-transform: none; } } }
.CartTable-QuantityBlock {
  align-items: center;
  .QButton {
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: var(--accent);
    box-shadow: var(--boxShadow);
    border-top-left-radius: 8px;
    border-bottom-right-radius: 8px;
    cursor: pointer; }
  .Button-IconContainer {
    background: none !important; }
  .QInput {
    width: 36px;
    appearance: none;
    outline: none;
    font-size: 14px;
    border: 0;
    border-bottom: 1px solid var(--borderColor);
    margin: 0px 8px;
    background: none;
    font-weight: 500; } }
.CartTable-ProdPrice {
  font-size: 18px;
  font-weight: 500;
  font-family: var(--activeFont); }
.CartTable-TotalPrice {}
