.MobileTable {
  display: none; }
.CartPage-Container {
  display: flex;
  justify-content: space-between; }

.Cart-TableContent {
  width: 100%;
  padding-right: 16px;
  border-right: 1px var(--borderColor) solid; }
.Cart-TableButtons {
  margin-top: 16px;
  display: flex;
  justify-content: space-between;
  align-items: center; }

.Cart-Totals {
  width: 320px;
  flex-shrink: 0;
  margin-left: 16px; }
.Cart-TotalsBlock {
  background-color: var(--accent2);
  width: 100%;
  padding: 16px;
  border-top-left-radius: 24px;
  border-bottom-right-radius: 24px;
  .TotalsLine, p {
    justify-content: space-between;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 14px;
    font-weight: 500;
    font-family: var(--activeFont); }
  .TotalsLine {
    &:not(:last-child) {
      margin-bottom: 8px; } }
  .TotalsButton {
    margin-top: 24px;
    display: flex;
    justify-content: center; } }

.CategoryTitle {
  text-transform: uppercase;
  height: 48px;
  display: flex;
  width: 100%;
  align-items: center;
  font-size: 18px;
  color: var(--accent2);
  margin: 0;
  padding: 0; }

@media ( max-width: 1024px) {
  .CartPage {
    margin-bottom: 0 !important; }
  .CartPage-Container {
    flex-direction: column; }

  .Cart-TableContent {
    padding-right: 0;
    border-right: 0; }

  .Cart-Totals {
    width: 100%;
    margin-left: 0;
    margin-top: 32px; } }

@media ( max-width: 576px) {
  .DesktopTable {
    display: none; }
  .MobileTable {
    display: block; } }
